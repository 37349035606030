import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTableComponent } from 'common-module/table';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { take } from 'rxjs';

import { DocumentModel } from '~/api/documents/document.model';

import { SelectDocumentTemplateData } from './select-document-template.data';
import { SelectDocumentTemplateProvider } from './select-document-template.provider';

@Component({
  standalone: true,
  selector: 'app-select-document-template',
  template: `
    <ng-container *nzModalTitle>
      {{ 'modal.documentTemplate.choose.title' | crmTranslate }}
    </ng-container>
    <crm-table [dataProvider]="provider"></crm-table>
    <ng-container *nzModalFooter>
      <button nz-button nzType="default" nzSize="large" (click)="dismiss()">
        {{ 'generic.cancel' | crmTranslate }}
      </button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CrmTableComponent, NzModalModule, CrmTranslatePipe, NzButtonModule],
  providers: [SelectDocumentTemplateProvider],
})
export class SelectDocumentTemplateComponent
  extends CrmModalRefDirective<DocumentModel, SelectDocumentTemplateData>
  implements OnInit
{
  protected readonly provider = inject(SelectDocumentTemplateProvider);

  ngOnInit() {
    this.provider.setType(this.data.type);

    this.provider.select$.pipe(take(1)).subscribe((data) => this.submit(data));

    setTimeout(() => {
      document.getElementById('filter-search_search-template')?.focus();
    });
  }
}
