import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmTableComponentConfig,
  CrmTableLoadParams,
} from 'common-module/table';

import { DocumentModel } from '~/api/documents/document.model';
import { DocumentsTemplatesApiService } from '~/api/documents/documents-templates-api.service';
import { defaultSearchFilterWithOptions } from '~/config/filter.config';

import { SignatureRequiredComponent } from '../../../components/document/signature-required.component';
import { TableSelectProvider } from '../../../providers/table-select.provider';

@Injectable()
export class SelectDocumentTemplateProvider extends TableSelectProvider<DocumentModel> {
  protected type!: string;

  protected override persistUrlParams = false;

  private readonly templatesService = inject(DocumentsTemplatesApiService);

  setType(type: string) {
    this.type = type;
  }

  override handleRowClick(row: DocumentModel) {
    this.select$.next(row);
  }

  protected override _loadMethod({ filters, pagination }: CrmTableLoadParams) {
    const { limit, skip } = pagination ?? {};
    const { filter } = filters ?? {};
    const { search } = filter ?? {};

    const params: CrmDictionary = {
      limit,
      skip,
      'meta.type': this.type,
      '$sort[name]': '1',
      status: 'active',
    };

    if (search) {
      params['$search'] = search;
    }

    return this.templatesService.list(params);
  }

  protected override getComponentConfig(): CrmTableComponentConfig<DocumentModel> {
    return {
      pageSizeHidden: true,
      filter: {
        filters: [
          defaultSearchFilterWithOptions({
            span: { sm: 24 },
            id: 'search-template',
          }),
        ],
        actions: { clear: { enabled: false } },
      },
      columns: [
        { dataIndex: 'name', title: 'generic.title' },
        { dataIndex: 'meta.category', title: 'generic.category' },
        {
          dataIndex: 'meta.signature',
          title: 'generic.signature',
          content: SignatureRequiredComponent,
        },
      ],
    };
  }
}
