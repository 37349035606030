import { inject } from '@angular/core';
import { crmCreateModalFn$ } from 'common-module/modal';
import { of, switchMap } from 'rxjs';

import { DocumentTemplateType } from '~/api/documents/document-template.type';
import { DocumentsTemplatesApiService } from '~/api/documents/documents-templates-api.service';

import { SelectDocumentTemplateComponent } from './select-document-template.component';

export const selectDocumentTemplateFn = () => {
  const openModal = crmCreateModalFn$();
  const service = inject(DocumentsTemplatesApiService);

  return (type: DocumentTemplateType) =>
    service.listAll({ 'meta.type': type, status: 'active' }).pipe(
      switchMap((templates) => {
        if (templates.length === 1) {
          return of(templates[0]);
        }

        return openModal(
          SelectDocumentTemplateComponent,
          { type },
          { nzClassName: 'default' },
        );
      }),
    );
};
